import React from 'react'

function IconCopy () {
  return (
    <svg className="icon -copy" width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1055.000000, -373.000000)" fill="currentColor" fillRule="nonzero">
          <g transform="translate(742.000000, 339.000000)">
            <g transform="translate(323.000000, 44.000000) rotate(-270.000000) translate(-323.000000, -44.000000) translate(313.000000, 34.000000)">
              <path d="M2.15909091,12.7083333 L2.15909091,4.49494949 L1.64141414,4.49494949 C0.177119232,4.49494949 0,5.27812933 0,6.74242424 L0,17.3484848 C0,18.8127798 1.18722024,20 2.65151515,20 L13.2575758,20 C14.7218707,20 15.7070707,19.8228808 15.7070707,18.3585859 L15.7070707,17.8409091 L7.29166667,17.8409091 C3.06815476,17.8409091 2.15909091,16.5918739 2.15909091,12.7083333 Z M17.3484848,-2.29670379e-14 L6.74242424,-2.29670379e-14 C5.27812933,-2.29670379e-14 4.09090909,1.18722024 4.09090909,2.65151515 L4.09090909,13.2575758 C4.09090909,14.7218707 5.27812933,15.9090909 6.74242424,15.9090909 L17.3484848,15.9090909 C18.8127798,15.9090909 20,14.7218707 20,13.2575758 L20,2.65151515 C20,1.18722024 18.8127798,-2.29670379e-14 17.3484848,-2.29670379e-14 Z" id="Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IconCopy
