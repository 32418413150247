import React from 'react'

function SearchIcon () {
  return (
    <svg className="icon -search" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-421.000000, -181.000000)" fill="currentColor" fillRule="nonzero">
          <g transform="translate(421.000000, 180.000000)">
            <g transform="translate(-0.000000, 1.000000)">
              <path d="M15.2124425,14.5734649 L11.4044167,10.612927 C12.383527,9.44900467 12.9199874,7.98455219 12.9199874,6.45999368 C12.9199874,2.89800934 10.021978,0 6.45999368,0 C2.89800934,0 0,2.89800934 0,6.45999368 C0,10.021978 2.89800934,12.9199874 6.45999368,12.9199874 C7.79721237,12.9199874 9.07151634,12.5166591 10.1610083,11.7510094 L13.9979637,15.7416003 C14.1583401,15.9081557 14.3740477,16 14.6052031,16 C14.8240003,16 15.0315627,15.9165818 15.1891304,15.7649124 C15.5239266,15.4427553 15.5345996,14.9085419 15.2124425,14.5734649 Z M6.45999368,1.68521574 C9.09286241,1.68521574 11.2347716,3.82712495 11.2347716,6.45999368 C11.2347716,9.09286241 9.09286241,11.2347716 6.45999368,11.2347716 C3.82712495,11.2347716 1.68521574,9.09286241 1.68521574,6.45999368 C1.68521574,3.82712495 3.82712495,1.68521574 6.45999368,1.68521574 Z" id="Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SearchIcon
