import React from 'react'

function EyeIcon () {
  return (
    <svg className="icon -eye" width="20px" height="14px" viewBox="0 0 18 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-701.000000, -405.000000)" fill="currentColor" fillRule="nonzero">
          <g transform="translate(701.000000, 405.000000)">
            <path d="M8.8000125,3.6 C7.476,3.6 6.4000125,4.6759875 6.4000125,6 C6.4000125,7.3240125 7.476,8.4 8.8000125,8.4 C10.124025,8.4 11.2000125,7.3240125 11.2000125,6 C11.2000125,4.6759875 10.1239875,3.6 8.8000125,3.6 Z" id="Path"></path>
            <path d="M8.8000125,0 C4.8,0 1.3840125,2.487975 0,6 C1.3840125,9.5119875 4.8,12 8.8000125,12 C12.804,12 16.2160125,9.5119875 17.600025,6 C16.2160125,2.487975 12.804,0 8.8000125,0 Z M8.8000125,9.999975 C6.5920125,9.999975 4.8,8.2079625 4.8,5.9999625 C4.8,3.7919625 6.5920125,1.9999875 8.8000125,1.9999875 C11.0080125,1.9999875 12.800025,3.792 12.800025,6 C12.800025,8.208 11.0080125,9.999975 8.8000125,9.999975 Z" id="Shape"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default EyeIcon
