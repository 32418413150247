import React from 'react'

function EyeCrossedIcon () {
  return (
    <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-685.000000, -376.000000)">
          <g transform="translate(451.000000, 362.000000)">
            <g transform="translate(234.000000, 14.000000)">
              <rect x="0" y="0" width="20" height="18" />
              <g transform="translate(0.000000, 0.360000)" fill="currentColor" fillRule="nonzero">
                <path d="M9.8477551,5.46692769 L12.7107347,8.32990727 L12.7243679,8.17994168 C12.7243679,6.67572709 11.5019353,5.45329445 9.99772069,5.45329445 L9.8477551,5.46692769 Z" />
                <path d="M9.99772069,3.63555804 C12.5062361,3.63555804 14.5421469,5.67146883 14.5421469,8.17998428 C14.5421469,8.76621343 14.4240064,9.32517611 14.2195079,9.83868047 L16.8779889,12.4971615 C18.2503872,11.3519697 19.3319714,9.87050556 20,8.17998428 C18.4231032,4.19000471 14.5467055,1.36336622 9.9977633,1.36336622 C8.72531372,1.36336622 7.5074397,1.59057262 6.3758811,1.9995697 L8.3390671,3.95819709 C8.85252886,3.75825716 9.41149154,3.63555804 9.99772069,3.63555804 Z" />
                <path d="M0.908868207,1.15882507 L2.9811201,3.23107696 L3.3946758,3.64463266 C1.89501982,4.81709097 0.708928279,6.3758385 0,8.17994168 C1.57238077,12.1699212 5.45329445,14.9965597 9.99772069,14.9965597 C11.4065026,14.9965597 12.7516344,14.723895 13.9831843,14.2285399 L14.3694735,14.6148291 L17.0188799,17.2687516 L18.177705,16.1144851 L2.06769328,0 L0.908868207,1.15882507 Z M5.93501633,6.18041458 L7.33923965,7.5846379 C7.29833994,7.78006182 7.27107347,7.97544313 7.27107347,8.17994168 C7.27107347,9.68415626 8.49350611,10.9065889 9.99772069,10.9065889 C10.2022192,10.9065889 10.3976432,10.8793224 10.5885085,10.8384227 L11.9927318,12.242646 C11.3883108,12.5425772 10.7157662,12.7243679 9.99772069,12.7243679 C7.48920525,12.7243679 5.45329445,10.6884571 5.45329445,8.17994168 C5.45329445,7.46193877 5.63508513,6.78935159 5.93501633,6.18041458 Z"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default EyeCrossedIcon
