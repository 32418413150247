import React from 'react'

import './_index.scss'

function StarIcon () {
  return (
    <svg className="icon -star" width="19px" height="18px" viewBox="0 0 19 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-36.000000, -95.000000)" fill="currentColor" fillRule="nonzero">
          <g transform="translate(20.000000, 86.000000)">
            <g transform="translate(16.000000, 9.000000)">
              <path d="M18.7338639,6.80198398 C18.6109079,6.42165181 18.2735674,6.15152141 17.8744622,6.11555178 L12.4532244,5.62329826 L10.3095211,0.605751291 C10.1514554,0.238030021 9.79147258,1.30335371e-16 9.3915076,1.30335371e-16 C8.99154262,1.30335371e-16 8.63155982,0.238030021 8.47349411,0.606611141 L6.32979077,5.62329826 L0.907693133,6.11555178 C0.509304484,6.15238123 0.172823779,6.42165181 0.0491513339,6.80198398 C-0.0745211115,7.18231611 0.0396931686,7.59947772 0.341064132,7.86244288 L4.43887794,11.4562521 L3.23052802,16.7790392 C3.14210869,17.1704059 3.29401222,17.5749566 3.61874195,17.8096906 C3.79328777,17.9357992 3.99749775,18 4.2034274,18 C4.38098262,18 4.55710477,17.9521361 4.71517048,17.8575546 L9.3915076,15.0626722 L14.0661251,17.8575546 C14.4081947,18.0633409 14.8394002,18.0445679 15.1634134,17.8096906 C15.4882864,17.5742401 15.6400467,17.169546 15.5516273,16.7790392 L14.3432774,11.4562521 L18.4410912,7.86315939 C18.7424622,7.59947772 18.8575363,7.18303263 18.7338639,6.80198398 Z" id="Path"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default StarIcon
