import React from 'react'

function IconAbout () {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-345.000000, -221.000000)" fill="currentColor" fillRule="nonzero">
          <g transform="translate(345.000000, 221.000000)">
            <path d="M10,0 C4.486,0 0,4.48606061 0,10.0000606 C0,15.5140606 4.486,20 10,20 C15.514,20 20,15.5140606 20,10.0000606 C20,4.48606061 15.514,0 10,0 Z M10,18.1818182 C5.48848485,18.1818182 1.81818182,14.5115152 1.81818182,10.0000606 C1.81818182,5.48860606 5.48848485,1.81818182 10,1.81818182 C14.5115152,1.81818182 18.1818182,5.48860606 18.1818182,10.0000606 C18.1818182,14.5115152 14.5114545,18.1818182 10,18.1818182 Z" id="Shape"></path>
            <path d="M9.99987879,4.24242424 C9.33163636,4.24242424 8.788,4.78642424 8.788,5.45509091 C8.788,6.12315152 9.33163636,6.66666667 9.99987879,6.66666667 C10.6681212,6.66666667 11.2117576,6.12315152 11.2117576,5.45509091 C11.2117576,4.78642424 10.6681212,4.24242424 9.99987879,4.24242424 Z" id="Path"></path>
            <path d="M10,8.48484848 C9.49793939,8.48484848 9.09090909,8.89187879 9.09090909,9.39393939 L9.09090909,14.8484848 C9.09090909,15.3505455 9.49793939,15.7575758 10,15.7575758 C10.5020606,15.7575758 10.9090909,15.3505455 10.9090909,14.8484848 L10.9090909,9.39393939 C10.9090909,8.89187879 10.5020606,8.48484848 10,8.48484848 Z" id="Path"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IconAbout
