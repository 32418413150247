import React from 'react'

function IconEdit () {
  return (
    <svg className="icon -edit" width="17px" height="16px" viewBox="0 0 17 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-975.000000, -52.000000)" fill="currentColor" fillRule="nonzero">
          <g transform="translate(963.000000, 40.000000)">
            <g transform="translate(12.000000, 12.000000)">
              <path d="M9.89124432,2.67414873 L1.07856982,11.4874584 C1.03423333,11.531922 1.00221958,11.5880731 0.986974932,11.6484165 L0.0101743906,15.5690847 C-0.0190445262,15.6871037 0.0156370628,15.8126179 0.101769284,15.8987502 C0.166940128,15.963921 0.255740186,16 0.346572863,16 C0.374394346,16 0.402851014,15.99657 0.430545434,15.9895828 L4.35121375,15.0126552 C4.41231935,14.9974106 4.46783526,14.9655239 4.51217179,14.9211874 L13.3256085,6.10851291 L9.89124432,2.67414873 Z" id="Path"></path>
              <path d="M15.5088956,1.47248971 L14.5279027,0.491496869 C13.872256,-0.164149844 12.7295429,-0.163514658 12.0746584,0.491496869 L10.8729994,1.69315592 L14.3072365,5.12739304 L15.5088956,3.92573398 C15.8364013,3.59835527 16.0167963,3.16261255 16.0167963,2.69917538 C16.0167963,2.23573821 15.8364013,1.79999546 15.5088956,1.47248971 Z" id="Path"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IconEdit
