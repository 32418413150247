import React from 'react'

function IconAdd () {
  return (
    <svg className="icon -add" width="16px" height="16px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-650.000000, -52.000000)" fill="currentColor" fillRule="nonzero">
          <g transform="translate(638.000000, 40.000000)">
            <g transform="translate(21.000000, 20.999961) rotate(-45.000000) translate(-21.000000, -20.999961) translate(15.000000, 15.000000)">
              <path d="M11.5943801,2.36357112 L7.95845074,5.99987943 L11.5943801,9.63601549 C12.1352066,10.1770487 12.1352066,11.0534502 11.5943801,11.5944834 C11.3241563,11.8647072 10.9698751,11.9999225 10.6157662,11.9999225 C10.2610716,11.9999225 9.90675603,11.8649139 9.63673893,11.5944834 L6.00001722,7.95793399 L2.36357112,11.594449 C2.09338177,11.8646728 1.73906615,11.9999225 1.38464718,11.9999225 C1.03033156,11.9999225 0.676257093,11.8648795 0.4058266,11.594449 C-0.134999935,11.0536569 -0.134999935,10.177221 0.4058266,9.63598104 L4.04165261,5.99984498 L0.405619902,2.36357112 C-0.135206634,1.82274458 -0.135206634,0.946136389 0.405619902,0.405309854 C0.946343088,-0.135103285 1.82243453,-0.135103285 2.36336442,0.405309854 L5.99998278,4.04161816 L9.63632553,0.405309854 C10.1773588,-0.135103285 11.0535536,-0.135103285 11.5941734,0.405309854 C12.1352066,0.946136389 12.1352066,1.82274458 11.5943801,2.36357112 Z" id="Path"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IconAdd
