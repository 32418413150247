import React from 'react'

import './_index.scss'

function IconLock () {
  return (
    <svg className="icon -lock" width="14px" height="20px" viewBox="0 0 14 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-41.000000, -392.000000)" fill="currentColor" fillRule="nonzero">
          <g transform="translate(20.000000, 383.000000)">
            <g transform="translate(21.000000, 9.000000)">
              <path d="M12.1212121,8.34066667 L12.1212121,5.15145455 C12.1212121,2.3109697 9.81024242,0 6.96969697,0 C4.12915152,0 1.81824242,2.3109697 1.81824242,5.15145455 L1.81824242,8.34054545 C0.689272727,9.57963636 0,11.226 0,13.030303 C0,16.8733939 3.12660606,20 6.96969697,20 C10.8127879,20 13.9393939,16.8733939 13.9393939,13.030303 C13.9393939,11.226 13.2501212,9.57963636 12.1212121,8.34066667 Z M6.96969697,10.6060606 C7.80515152,10.6060606 8.48484848,11.2857576 8.48484848,12.1212121 C8.48484848,12.6158182 8.24654545,13.0558182 7.87878788,13.3324848 L7.87878788,13.3324848 L7.87878788,14.5454545 C7.87878788,15.0475152 7.47175758,15.4545455 6.96969697,15.4545455 C6.46763636,15.4545455 6.06060606,15.0475152 6.06060606,14.5454545 L6.06060606,14.5454545 L6.06060606,13.3324848 C5.69284848,13.0558182 5.45454545,12.6158788 5.45454545,12.1212121 C5.45454545,11.2857576 6.13424242,10.6060606 6.96969697,10.6060606 Z M6.96969697,1.81818182 C8.80769697,1.81818182 10.3030303,3.31351515 10.3030303,5.15145455 L10.3030303,6.91078788 C9.31224242,6.3689697 8.17636364,6.06060606 6.96969697,6.06060606 C5.76309091,6.06060606 4.62721212,6.3689697 3.63642424,6.91078788 L3.63642424,5.15145455 C3.63642424,3.31351515 5.13169697,1.81818182 6.96969697,1.81818182 Z" id="XMLID_811_"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IconLock
