import React from 'react'

function IconDelete () {
  return (
    <svg className="icon -delete" width="14px" height="16px" viewBox="0 0 14 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1071.000000, -52.000000)" fill="currentColor">
          <g transform="translate(1057.000000, 40.000000)">
            <path d="M20.7934646,12 C23.3696265,12 26.0594102,12.7820802 27.2100717,13.8808164 C27.47208,14.1310013 27.5941332,14.3748392 27.5762313,14.6123302 L26.2348326,25.7567605 C26.0396214,26.8930639 25.207243,27.2385221 24.9099436,27.379723 C24.0902231,27.7690455 23.1354635,28 20.788117,28 C18.4407705,28 17.8484474,27.7690455 16.8899959,27.379723 C16.5846384,27.2385221 15.6868038,26.8930639 15.3653421,25.7567605 C15.3653421,25.7567605 14.9102281,22.0419504 14,14.6123302 C14.0259871,14.3748392 14.1723032,14.1310013 14.4389484,13.8808164 C15.490908,12.8937955 18.2773779,12 20.7934646,12 Z M20.7934646,13.14 C16.7441065,13.14 15.0874389,14.3143409 15.0874389,15.0271205 C15.0874389,15.7399001 16.7441065,16.8968176 20.7934646,16.8968176 C24.8428228,16.8968176 26.5371588,15.7399001 26.5371588,15.0271205 C26.5371588,14.3143409 24.8428228,13.14 20.7934646,13.14 Z" id="Rectangle-2"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IconDelete
