import React from 'react'

function ShieldIcon () {
  return (
    <svg className="icon -shield" width="20px" height="24px" viewBox="0 0 20 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-345.000000, -186.000000)" fill="currentColor" fillRule="nonzero">
          <g transform="translate(345.000000, 186.000000)">
            <path d="M19.4291204,3.83823495 L10.3550463,0.0707812511 C10.1277315,-0.0235706008 9.87222222,-0.0236168971 9.6449537,0.0707812511 L0.57087963,3.83823495 C0.225277778,3.98175347 0,4.31916088 0,4.69337384 L0,9.27323495 C0,15.5793924 3.81152778,21.2519387 9.65009259,23.6350405 C9.87435185,23.7265683 10.1256019,23.7265683 10.3499074,23.6350405 C16.1883796,21.251985 20,15.5794387 20,9.27323495 L20,4.69337384 C20,4.31916088 19.7747685,3.98175347 19.4291204,3.83823495 Z M18.1481481,9.27323495 C18.1481481,14.6341146 15,19.5691609 10,21.7720313 C5.13361111,19.6280035 1.85185185,14.7793924 1.85185185,9.27323495 L1.85185185,5.31152199 L10,1.92846644 L18.1481481,5.31152199 L18.1481481,9.27323495 Z M8.97657407,12.3942535 L12.9563889,8.41448495 C13.317963,8.05291088 13.904213,8.05286458 14.2658333,8.41448495 C14.6274537,8.77610533 14.6274074,9.36235533 14.265787,9.7239294 L9.6312963,14.3584201 C9.26962963,14.7200868 8.68337963,14.7199479 8.32185185,14.3584201 L5.73416667,11.770735 C5.3725463,11.4091146 5.3725463,10.8228646 5.73416667,10.4612905 C6.09578704,10.0997164 6.68203704,10.0996701 7.04361111,10.4612905 L8.97657407,12.3942535 Z" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ShieldIcon
