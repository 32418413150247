import React from 'react'

function CrossIcon () {
  return (
    <svg className="icon -cross" width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1052.000000, -54.000000)" fill="currentColor" fillRule="nonzero">
          <g transform="translate(1037.000000, 40.000000)">
            <g transform="translate(15.000000, 14.000000)">
              <path d="M12.5605784,2.56053538 L8.62165497,6.49986938 L12.5605784,10.4390168 C13.1464739,11.0251361 13.1464739,11.9745711 12.5605784,12.5606904 C12.267836,12.8534328 11.8840314,12.999916 11.5004134,12.999916 C11.1161609,12.999916 10.732319,12.8536568 10.4398005,12.5606904 L6.50001866,8.62109516 L2.56053538,12.5606531 C2.26783025,12.8533955 1.88398833,12.999916 1.50003445,12.999916 C1.11619253,12.999916 0.73261185,12.8536194 0.439645483,12.5606531 C-0.14624993,11.974795 -0.14624993,11.0253227 0.439645483,10.4389795 L4.378457,6.49983206 L0.43942156,2.56053538 C-0.146473853,1.97463996 -0.146473853,1.02498109 0.43942156,0.439085675 C1.02520501,-0.146361892 1.97430408,-0.146361892 2.56031145,0.439085675 L6.49998134,4.37841968 L10.4393527,0.439085675 C11.025472,-0.146361892 11.974683,-0.146361892 12.5603545,0.439085675 C13.1464739,1.02498109 13.1464739,1.97463996 12.5605784,2.56053538 Z" id="Path"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CrossIcon
